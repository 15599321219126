exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-childrens-vision-center-js": () => import("./../../../src/pages/childrens-vision-center.js" /* webpackChunkName: "component---src-pages-childrens-vision-center-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dry-eye-center-js": () => import("./../../../src/pages/dry-eye-center.js" /* webpackChunkName: "component---src-pages-dry-eye-center-js" */),
  "component---src-pages-eyeglasses-js": () => import("./../../../src/pages/eyeglasses.js" /* webpackChunkName: "component---src-pages-eyeglasses-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-patient-services-js": () => import("./../../../src/pages/patient-services.js" /* webpackChunkName: "component---src-pages-patient-services-js" */),
  "component---src-pages-start-here-js": () => import("./../../../src/pages/start-here.js" /* webpackChunkName: "component---src-pages-start-here-js" */)
}

